exports.components = {
  "component---src-components-templates-article-article-js": () => import("./../../../src/components/templates/Article/Article.js" /* webpackChunkName: "component---src-components-templates-article-article-js" */),
  "component---src-components-templates-project-project-js": () => import("./../../../src/components/templates/Project/Project.js" /* webpackChunkName: "component---src-components-templates-project-project-js" */),
  "component---src-components-templates-tag-tag-js": () => import("./../../../src/components/templates/Tag/Tag.js" /* webpackChunkName: "component---src-components-templates-tag-tag-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */)
}

